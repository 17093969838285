.telegramButton {
    display: inline-block;
    padding: 1rem;
    border-radius: 30px;
    background: linear-gradient(90deg, #D758CD 0%, #6996DB 100%);
    color: #03070F;

    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 18px;
    font-weight: 900;
    line-height: 28px;
    text-align: center;

    svg {
        width: 24px;
        height: 24px;
        vertical-align: text-top;
        fill: #03070F;
    }

    &.block {
        display: block;
        width: 100%;
        text-align: center;
    }

    &.outlined {
        border: 1px solid #A93AFF;
        background: transparent;
        color: var(--tg-theme-primary-color );
    }

    &:disabled {
        opacity: 0.5;
    }
}