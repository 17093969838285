.pickleball {
  width: 50px;
  height: 50px;
  position: absolute;
  //top: -200px;
  //z-index: 1;
  z-index: 100;
  //top: 200px;
  //cursor: pointer;
  pointer-events: auto;

}

.disabled-ball {
  pointer-events: none;
}

.flame {
  width: 176px;
  height: 138px;
  position: absolute;
  //top: -200px;
  z-index: 900;
  //cursor: pointer;
  pointer-events: auto;
}
.pickleball-white {
  path {
    fill: #fff;
  };
}
.pickleball-yellow {
  path {
    fill: $light-yellow;
  };
}
.pickleball-green {
  path {
    fill: $light-green;
  };
}
.pickleball-blue {
  path {
    fill: $light-blue;
  };
}
.pickleball-pink {
  path {
    fill: $light-pink;
  };
}

.pickleScore {
  position: absolute;
  //z-index: 1000;
  font-family: 'Knewawe', sans-serif;
  font-size: 30px;
  font-weight: 400;
  color: $lilac;
  z-index: 1000;
}

.pickleScore--min {
  color:  #6AD2FF;
  font-size: 18px;
  font-weight: 400;
}
.pickleScore--min-3 {
  color: $light-pink;
  font-size: 18px;
  font-weight: 400;
}


.cursor {
  //position: fixed;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90.46px;
  height: 100px;
  //border-radius: 50%;
  pointer-events: none;
  z-index: 99999;
  background-size: cover;
  transition: transform 0.02s linear;

  &.cursor--desktop {
    width: 64px;
    height: 70px;
  }
}

.animation-screen {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

.animation-container {
  //position: relative;
  width: 100vw;
  height: 100vh;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  z-index: 9999; /* Make sure it's on top */
}

.spinner {
  border: 8px solid $white;
  border-top: 8px solid $blue-dim;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-small {
  margin-left: 5px;
  border: 1px solid #D758CD;
  border-top: 1px solid #A93AFF;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}