.clipboard-button {
    background: transparent;
    text-align: center; 
    color: #A93AFF;
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 12px;
    font-weight: 900;
    line-height: 14px;

    svg {
        fill: #A93AFF;
    }
}