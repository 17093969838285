@import '../../../styles/variables';

.telegramText {
    margin: 0 0 1rem;

    @media screen and (max-width: 768px) {
        &.mobile-small {
            font-size: 14px;
            line-height: 18px;
        }
    }
}

.telegramTitle {
    font-weight: bolder;
    font-size: 22px;
    text-align: center;
    align-self: center;
}

.telegramMainTitle {
    margin: 0;
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 36px;
    font-weight: 900;
    line-height: 42.96px;
    text-align: left;

    
    @media screen and (max-width: 360px) {
        font-size: 24px;
        line-height: 28px;
    }
}

.telegramSubtitle {
    margin: 0 0 8px;
    color: rgba(255,255,255,0.6);
    font-weight: normal;
    font-size: 16px;
}

.telegramPrimaryText {
    color: $violet;
    font-weight: bold;
    font-size: 16px;
    vertical-align: text-top;

    img  {
        vertical-align: middle;
        margin-right: 4px;
    }
}

.telegramHint {
    font-size: 14px;
    font-weight: normal;
}

.white-opacity {
    color: rgba(255, 255, 255, .6);
}

.splash-text {
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30.62px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

h2 {
    margin-bottom: 1rem;
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1.167;
    text-align: left;
    color: $violet;
}

h3 {
    font-weight: bold;
    font-size: 1rem;
}

h4 {
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 16px;
    font-weight: 900;
    line-height: 19.09px;
    text-align: left;
    color: $violet;
}

.regularText {
    margin-bottom: 1rem;
    font-size: 12px;
    line-height: 24px;
    font-family: 'SpaceGrotesk', sans-serif;
    color: $violet;
    
    svg {
      vertical-align: top;
    }
}