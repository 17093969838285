.main-wrapper--inactive {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height:100vh;
  width:100vw;
}
.main-wrapper {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height:100vh;
  width:100vw;
}
.main-wrapper--game {
  // background-image: url("../../assets/backgrounds/bgpattern3.png");
  //background-image: url("../../assets/bgpattern1.png");
  // background-repeat: repeat;
  // background-position: center;
  //background-size: cover;
  // height:100vh;
  // width:100vw;


  &.main-wrapper--game--timeout {
    filter: grayscale(100%);
  }
}

.splash-wrapper {
  background-image: url("../../assets/splash.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height:100vh;
  width:100vw;
  z-index: 100 !important;
}

.white-background{
    background: linear-gradient(169deg, #EEF9FB 0%, #F9FDFE 46.5%, #EEF9FB 100%);
    background-color: #fff;
}

.splash-wrapper__holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  color: white;
}

.splash-wrapper__frame {
  svg {
    margin: 0 0 10px;
    display: inline-block;  
    vertical-align: top;
  }

  p {
    margin: 0 0 40px;
  }

  .loading {
    line-height: 24px;
  }
}

.payment-container {
  position: relative;
  min-height: 100vh;
  padding-top: 70px;

  .payment-container__page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    color: white;
  }
}

.payment-block {
  width: 100%;
  align-self: center;
  text-align: center;

  .payment-block__title {
    display: block;
    font-family: 'SF Mono', monospace;
    font-size: 80px;
    font-weight: 900;
    line-height: 95px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    @media screen and (min-width: 768px) {
      font-size: 160px;
      line-height: 160px;
    }
  }
  .payment-block__subtitle {
    font-family: 'SF Mono', monospace;
    font-size: 16px;
    font-weight: 900;
    line-height: 19.09px;
    text-align: center;
    color: gray;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    @media screen and (min-width: 768px) {
      font-size: 32px;
      line-height: 38.19px;
    }
  }
}

.btns-holder {
  padding: 0 1.25rem;

  > * {
    display: block;
    margin: 0 0 1rem;
    
    @media screen and (min-width: 768px) {
      display: inline-block;
      margin: 0 1rem;
    }
  }

  .telegramButton {
    display: block;
    width: 100%;
    text-align: center;

    @media screen and (min-width: 768px) {
      display: inline-block;
      width: auto;
    }
  }
}

.main-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  color: white;
  padding-bottom: 70px;

  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 100vh;
  }

  &.main-container-dim {
    padding: 20px;
    background-color: rgba($black, 0.6);
    backdrop-filter: blur(6px);
  }
}

.sketch {
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    min-height: calc(100vh);
  }
}

.welcome-holder {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(186.81deg, rgba(3, 7, 15, 0) 5.33%, #03070F 65.97%);

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}

.preview-holder {
  @media screen and (max-width: 768px) {
    padding-top: 40px;
  }
}

.preview-block {
  position: relative;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);

  @media screen and (max-width: 768px) {
    min-height: 0;
    padding: 0;
    margin-bottom: 1rem;
    .welcome {
      margin: 0;
      padding: 0;
      
      &.style01 {
        .decor {
          position: absolute;
        }
      }
    }
  }

  .welcome {
    min-height: 207px;
    padding: 25px;

    img {
      vertical-align: top;
      margin-bottom: 20px;
    }

    .text-bold-roboto {
      font-size: 24px;
      line-height: 30px;
    }
    .telegramText {
      margin: 0 0 1rem;
    }
    .telegramSubTitle {margin: 0;}
    .preview {
      text-transform: uppercase;
      font-family: 'SpaceGrotesk', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $violet;

      svg {
        display: inline-block;
        vertical-align: top;
        margin-right: .5rem;
      }
    }

    .decor {
      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: rgba(43, 48, 57, 0.4);
      }
    }
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  
  justify-content: flex-end;
  padding: 2.5rem 2.5rem;
  margin: 0 -40px;

  @media screen and (min-width: 768px) {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 70px);
    height: calc(100vh - 70px);
    border-radius: 40px;
  }

  &.grow {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 70px);
    height: calc(100vh - 70px);
  }

  .telegramMainTitle {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 768px) {
    height: auto;
    min-height: 610px;
  }

  &.style01 {
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background: linear-gradient(186.81deg, rgba(3, 7, 15, 0) 5.33%, #03070F 65.97%);
  
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .decor {
      @media screen and (min-width: 768px) {
        transform: translate(-50%, -50%);
      }
    }
  }

  .decor {
    content: '';
    position: fixed;
    z-index: -2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media screen and (min-width: 768px) {
      position: absolute;
      border-radius: 40px;
      transform: translate(-50%, -50%) scaleX(-1);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 2.5rem;
    margin: 0 0 2.5rem;
  }
}


.fixed-decor {
  content: '';
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.columns-holder {
  color: white;

  .column {
    width: 100%;
    padding: 0 40px;

    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }
  }

  &.style01 {
    @media screen and (max-width: 768px) {
      padding: 0;
      display: flex;
      flex-direction: column;
    
      .column:nth-child(2) {
        order: 1;
        padding: 0;
      }
      .column:nth-child(1) {
        order: 2;
        padding: 0;
      }
    }

    .welcome {
      margin: 0;  
      padding: 0 1rem 1rem;
      text-align: center;

      .telegramMainTitle {
        margin-bottom: 0;
        text-align: left;
        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }


      @media screen and (min-width: 768px) {
        min-height: 453px;
        text-align: left;
        border-radius: 40px;
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
    }

    .decor {
      transform: none;
      z-index: -1;
      height: 230px;
      left: 0;
      top: 0;
      border-radius: 40px 40px 0 0;

      @media screen and (max-width: 768px) {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        overflow: hidden;
        background: linear-gradient(186.81deg, rgba(3, 7, 15, 0) 5.33%, #03070F 65.97%);
      }

      img {
        top: auto;
        bottom: 0;
        left: 0;
        transform: none;
        height: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      .decor {
        top: auto;
        bottom: 0;
  
      }
    }
  }

  .text-holder {
    padding: 2.5rem 0;
    @media screen and (max-width: 768px) {
      padding: 2.5rem 20px;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 768px) {
    display: flex;
    max-width: 1040px;
    padding-top: 100px;
    margin: 0 auto;

    .column {
      width: 50%;
      padding: 0 50px;
    }
  }
}

.cards-container {
  max-width: 1040px;
  margin: 0 auto;
  padding: 20px;

  @media screen and (min-width: 768px) {
    padding: 0 50px;
    padding-top: 100px;
  }
  &.cards-container-dim {
    // padding: 20px;
    // background-color: rgba($black, 0.6);
    // backdrop-filter: blur(6px);
    // background: linear-gradient(169deg, #EEF9FB 0%, #F9FDFE 46.5%, #EEF9FB 100%), #FFF;
  }
}


.coin-card-container {
    //background-image: url("../../assets/purr1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height:100vh;
    width:100vw;

  &.active {
    //background-image: url("../../assets/purr2.png");
    transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
  }
}

.hidden-mobile {
  display: none !important;

  @media screen and (min-width: 768px) {
    display: block !important;
  }
}

.visible-mobile {
  display: block !important;

  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

/* details page */
.details-section {
  @media screen and (min-width: 768px) {
    max-width: 1040px;
    margin: 0 auto;
    padding: 0 50px;
  }
}
.details-header {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.back-button {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $violet;
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.back-button .icon {
    margin-right: 5px;
}

.social-icons {
    display: flex;
    align-items: center;
}

.social-icons .icon {
    margin-left: 10px;
    cursor: pointer;
}

.details-section {
  margin-bottom: 2px;

  &.closed .section-header {
      background-color: rgba(169, 58, 255, 0.2);
  }
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding: .75rem 1rem;
}

.section-title {
    color: $violet;
}

.hide-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $violet;
  font-family: 'SpaceGrotesk', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;

  @media screen and (min-width: 768px) {
    display: none;
  }

}

.hide-icon img {
  margin-left: 5px;
}