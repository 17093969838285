.sell-modal.open {
  transform: translateY(0);
  bottom: 0%;
}

.sell-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  padding: 8px 16px 8px 8px;
  gap: 16px;
  border-radius: 12px;
  height: 94vh;
  width: 100vw;
  position: fixed;
  bottom: -100%;
  transition: transform 0.5s ease;
  transform: translateY(100%);
  left: 0;

  .sell-modal--header {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .modal-close-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
    border-radius: 1000px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  }
  .sell-modal--header--text {
    color: $blue-dim;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 600;
  }

  .modal-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal-main-container--img {
    height: 180px;

    &.modal-main-container--img-min {
      height: 140px;
    }
  }
  .modal-main-container--text {
    color: $black-soft;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
  }

  .sell-modal--info {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .sell-modal--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .sell-modal--row-text {
      display: flex;
      flex-direction: column;
      //gap: 5px;
      color: $blue-dim;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-weight: 600;
    }
    .sell-modal--row-text-mini {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: $blue;
    }
    .sell-modal--row-price {
      display: flex;
      flex-direction: row;
      gap: 5px;
      color: $blue-dim;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-weight: 700;
    }

    .number-input-wrapper {
      display: flex;
      align-items: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    textarea:focus, input:focus {
      outline: none;
    }

    .modal-number-input {
      width: 60px;
      text-align: center;
      padding: 5px;
      font-size: 16px;
      border: none;
    }

    .number-input--btn {
      background-color: $blue-dim;
      border: 1px solid $blue-dim;
      color: $white;
      font-size: 18px;
      padding: 5px 10px;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .minus {
      border-radius: 5px 0 0 5px;
    }

    .plus {
      border-radius: 0 5px 5px 0;
    }

    .number-input--btn:active {
      background-color: $violet-lighter;
    }
  }

}
.modal-sell-img-dim {
  filter: grayscale(100%);
}

.picklezone-card--img-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  border: 1px solid #0000000D;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(245, 245, 245, 1);

}

.sell-modal--button {
  margin-bottom: 160px;

  @media all and (min-width: 360px){
    margin-bottom: 175px;
  }
}
