.show-button {
  display: flex;
}
.hide-button {
  display: none;
}

.text-wrap {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button__connect {
  width: calc(100vw - 60px);
}

.cta-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.radio-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.radio-button {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  border-radius: 30px;
  border: 1px solid #A93AFF;
  color: #A93AFF;
  background: transparent;

  font-family: 'SpaceGrotesk', sans-serif;
  font-size: 18px;
  font-weight: 900;
  line-height: 28px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  svg {
    width: 24px;
    height: 24px;
    vertical-align: text-top;
    fill: #03070F;
  }

  input {
    visibility: hidden;
    position: absolute;
  }

  &.block {
    display: block;
    width: 100%;
    text-align: center;
  }

  &.bronze {
    border-color: #C79974;
    color: #C79974;

    &.checked {
      background: #C79974;
      color: #03070F;
    }
  }

  &.silver {
    border-color: #B0CCF4;
    color: #B0CCF4;

    &.checked {
      background: #B0CCF4;
      color: #03070F;
    }
  }

  &.gold {
    border-color: #FFAE00;
    color: #FFAE00;

    &.checked {
      background: #FFAE00;
      color: #03070F;
    }
  }
}