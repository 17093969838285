// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 16px;
}

body {
  font-size: clamp(1rem, 2vw + 1rem, 1rem);
  background-color: $bg-color;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;

  &.link {
    color: $violet;
  }

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s linear;
  }
}

img {
  display: inline-block;
  vertical-align: top;
}

// todo
.container {
  width: 100%;
}