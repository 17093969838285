// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/* ----------------------------- SF Pro Display ----------------------------- */
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/fonts/SpaceGrotesk-Light.ttf");
}
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/SpaceGrotesk-Regular.ttf");
}
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/SpaceGrotesk-Medium.ttf");
}
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/SpaceGrotesk-SemiBold.ttf");
}
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/SpaceGrotesk-Bold.ttf");
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/RobotoCondensed-Regular.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/RobotoRegular.ttf");
}


@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/RobotoMedium.ttf");
}


@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/RobotoBold.ttf");
}

