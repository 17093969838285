@media screen and (min-width: 768px) {
    .details-section {
        .crypto-info {
            padding: 0;
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        }
    }
}

.crypto-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #FFFFFF1A;

    @media screen and (min-width: 768px) {
        .details-section & {
            display: block;
            padding: 20px;
            border-radius: 20px;
            background: rgba(43, 48, 57, 0.4);
            border: 1px solid rgba(255, 255, 255, 0.15);
    
            .telegramSubtitle {
                color: white;
                font-family: 'SpaceGrotesk', sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
            }

        }
    }
    
    @media screen and (max-width: 768px) {
        &:not(.details-section .crypto-box) {
            margin-bottom: 10px;
            border-radius: 20px;
            border: 1px solid rgba(255, 255, 255, 0.15);
            background: rgba(43, 48, 57, 0.4);
        }
    }

    .crypto-box__img {
        width: 2rem;
        text-align: center;
        @media screen and (min-width: 768px) {
            margin-bottom: 20px;
        }
    }

    .crypto-box__btn {
        min-width: 2rem;
        text-align: center;

        @media screen and (min-width: 768px) {
            text-align: left;
            padding: 20px 0 0;
            button {
                padding: 0;
                > * {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 3px;
                }
            }
        }
    }

    .crypto-box__text {
        word-break: break-all;
        padding: 0 1rem;
        width: 100%;
        text-align: left;
        @media screen and (min-width: 768px) {
            padding: 0;
        }
    }

    p {margin: 0;}
}