// add css root variables
$gradient: linear-gradient(90deg, #D758CD 0%, #6996DB 100%);
$white: #ffffff;
$whiteSnow: #fdfdfd;
$black: #000000;
$bg-color: #000000;
$black-soft: #03070F;
$accent: #B6FFFA;
$accent-lighter: #4799b2;
$red: #FF4646;
$violet: #A93AFF;
$violet-lighter: #D758CD;
$blue-dim: #3A849A;
$light-green: #3DFD91;
$light-pink: #FF1694;
$light-blue: #8AEDFF;
$light-yellow: #FFFB81;
$lilac: #DA47FF;
$blue: #6AD2FF;
