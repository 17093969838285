.leaderboard {
  margin: 0 -40px 70px;

  @media screen and (max-width: 768px) {
    margin: 0 -20px 40px;
  }
}

.leaderboard-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;

  &.playing {
    background: linear-gradient(0deg, rgba(169, 58, 255, 0.25) 0%, rgba(169, 58, 255, 0) 100%);
  }

  &+& {
    border-top: 1px solid rgba(169, 58, 255, 0.1);
  }

  .leaderboard-info {
    flex-grow: 1;
    text-align: left;
  }

  .leaderboard-logo {
    text-align: center;
    width: 84px;

    img {
      vertical-align: top;
      margin-bottom: 4px;
      max-width: 54px;
      max-height: 54px;
    }
  }

  .leaderboard-vote,
  .leaderboard-reward,
  .track-number {
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 15.31px;
    color: rgba(255, 255, 255, .40);
  }
  .leaderboard-vote,
  .track-number {text-align: center;}

  .leaderboard-title {
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25.52px;
    text-align: left;
  }
}

.current-track-player {
  position: relative;
  margin-top: 20px;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(169, 58, 255, .1);
  overflow: hidden;
  z-index: -1;

  @media screen and (min-width: 768px) {
    z-index: 0;
  }
}

.progress {
  height: 100%;
  background: rgba(169, 58, 255, .4);
  transition: width 0.1s linear;

  @media screen and (min-width: 768px) {
    position: relative;
    z-index: 2;
  }
} 

.current-track-player {
  position: fixed;
  overflow: hidden;
  bottom: 70px;
  left: 0;
  width: 100%;
  background: rgba(169, 58, 255, .15);
  padding: 1px;

  @media screen and (min-width: 768px) {
    border-radius: 30px;
    position: relative;
    bottom: auto;
    background: transparent;
  }

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: #000;
    z-index: -1;

    @media screen and (min-width: 768px) {
      border-radius: 30px;
      z-index: 0;
    }
  }

  .leaderboard-row {
    position: relative;
    z-index: 1;

    .leaderboard-index svg{
      vertical-align: top;
    }

    .leaderboard-info {
      padding: 0 1rem;
      flex-grow: 1;
      text-align: left;
    }

    &.playing {
      background: transparent;
    }
  } 
}

.toggle-view-button {
  display: flex;
  margin: 0 1rem;
  font-family: 'SpaceGrotesk', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: #A93AFF;
}

.toggle-view-button svg {
  margin-right: 8px;
}
.leaderboard-filters {
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 20px;
  padding: 4px;
  margin: 0 10px 20px;
  overflow: hidden;

  .filter-button {
    flex: 1;
    border: none;
    background: transparent;
    color: $violet;
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;    
    padding: 8px 20px;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;
  }

  .filter-button.active {
    background-color: rgba(255, 255, 255, .1);
    color: #fff;
    border-radius: 15px;
  }

  .filter-button:not(.active):hover {
    color: $violet;
  }
}