.main-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #000;
  border-bottom: 1px solid rgba(255, 255, 255, .15);

  @media screen and (min-width: 768px) {
    top: 0;
    bottom: auto;
  }

  .main-navigation__holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      padding: 0 50px;
    }
  }

  .logo {
    display: none;

    svg {
      fill: $violet;
    }
  
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
}

.main-navigation-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 70px;
  background-color: #0A0909CC;
  border-top: 1px solid rgba(255, 255, 255, .05);
  box-shadow: 0 4px 80px 0 #00000040;
  border-top: 1px solid #FFFFFF1A;
  z-index: 9900;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    max-width: 320px;
  }
}

.main-navigation__cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 16%;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: rgba(255, 255, 255, .6);
  }

  &:hover,
  &.main-navigation__cards--active {
    .main-navigation__text {
      color: $white;
    }
    svg {
      fill: $violet;
    }
  }

}

.main-navigation-dropdown {
  padding: 20px 0;
  overflow: auto;

  @media screen and (min-width: 768px) {
    z-index: 9999;
  }

  .telegramSubTitle {
    margin: 0 0 10px  ;
    text-align: center;
    text-transform: uppercase;
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
    color: #A93AFF;

  }
  .btns-holder {
    display: flex;

    > * {
      margin: 0 5px;
    }
  }

  .decor {
    content: '';
    position: fixed;
    z-index: -2;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      position: absolute;
      top: -2vh;
      left: 0;
      width: 100%;
      height: auto;
    }

    &::after {
      background: linear-gradient(186.81deg, rgba(3, 7, 15, 0) 26.6%, #03070F 50.09%);
      position: absolute;
      top: -15vh;
      left: 0;
      width: 100%;
      height: 75vh;
      content: '';
    
      @media screen and (min-width: 768px) {
        top: 0;
        height: 100vh;
      }
    }
  }
}

.main-navigation-dropdown__cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  font-family: 'SpaceGrotesk', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.62px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  &+.main-navigation-dropdown__cards {
    border-top: 1px solid rgba(255, 255, 255, .15);
  }

  svg {
    fill: rgba(255, 255, 255, .6);
  }

  &:hover,
  &.main-navigation-dropdown__cards--active {
    .main-navigation-dropdown__text {
      color: $white;
    }
    svg {
      fill: $violet;
    }
  }

  .main-navigation-dropdown__text--holder {
    display: flex;
    align-items: center;

    svg {margin-right: 16px;}
  }

  .main-navigation-dropdown__chevron {}
}

.main-navigation__text {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: rgba($white, 0.6);
}


.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.main-navigation-dropdown {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 70px);
  color: white;
  background-color: #000;
}