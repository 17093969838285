.social-holder {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: block;

        .telegramText {
            margin: 0 0 .5rem;
        }
    }

    .telegramPrimaryText {
        position: relative;
        display: inline-block;
        padding-right: 30px;
    }
    .social-icons {
        display: inline-block;
        @media screen and (max-width: 1024px) {
            .social-text {
                display: none;
            }
        }
        @media screen and (max-width: 768px) {
            display: none;
        }

        > * {
            display: inline-block;
            text-transform: none;
        }
    }
}

.lastChange {
    position: absolute;
    left: 100%;
    margin-left: 10px;
    animation: fadeIn 0.2s, fadeOut 0.4s 0.4s;
}

.lastChange.positive {
    color: #04FF04;
    animation: fadeIn 0.2s, moveUp 0.4s 0.4s, fadeOut 0.4s 0.4s;
}

.lastChange.negative {
    color: #FF0404;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes moveUp {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-20px);
    }
}

.share-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 23px 40px 10px;

    @media screen and (max-width: 1024px) {
        padding: 10px;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }

    svg {
        fill: #A93AFF;
    }
    .share-button {
        color: #A93AFF;
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
}

.share-holder {
    position: relative;
}

.share-buttons {
    > * {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }
}

@media screen and (max-width: 768px) {
    .share-tooltip {
        position: absolute;
        top: -55px;
        right: -17px;
        background: #131620;
        border: 1px solid #363941;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        gap: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        button,
        svg {
            vertical-align: top;
        }

        svg {
            fill: white;
        }

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-top: 9px solid #131620;
        }

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #363941;
        }
    }
}

.share-buttons button {
    background: none;
    border: none;
    cursor: pointer;
}