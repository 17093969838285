$animation1-height: calc(55vh - 100px);
$animation2-height: calc(55vh - 150px);

.coin__right {
  position: absolute;
  //bottom: 200px;
  left: 150px;
  z-index: 1000;
  visibility: hidden;
}

.animation__coin-right {
  animation: coin-right-up 0.5s ease infinite;
  -webkit-animation: coin-right-up 0.5s ease;
}

@keyframes coin-right-up {
  0% {
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    transform: translateY($animation1-height);
    right: 30px;
  }
}

.coin__left {
  position: absolute;
  bottom: 200px;
  left: 150px;
  z-index: 10000;
  visibility: hidden;
  font-size: 20px;
  font-weight: 600;
}
.giphy-embed {
  position: absolute;
  border-radius: 10000px;
}
.cat-coin {
  width: 100px;
  height: 20px;
  margin-bottom:100%;
  position: relative;
  border-radius: 10000px;
}
.animation__coin-left {
  animation: coin-cat-up 0.8s ease ;
  -webkit-animation: coin-cat-up 0.8s ease;
}


@keyframes coin-cat-up {
  0% {
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    transform: translateY($animation2-height);
    left: 30px;
    visibility: hidden;
  }
}


.sleep-card {
  position: absolute;
  bottom: 300px;
  left: 140px;
  z-index: 1000;
  visibility: hidden;
}

.animation__sleep-card {
  animation: sleep-card-up 0.6s ease ;
  -webkit-animation: sleep-card-up 0.6s ease;
}

@keyframes sleep-card-up {
  0% {
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    top: calc(97vh - 100px);
    left: 30px;
  }
}


.card__tasks--title {
  display: block;
  padding: 0 40px 40px;
  font-family: 'SpaceGrotesk', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
  color: white;

  @media screen and (max-width: 768px) {
    padding: 0 20px 20px;
  }
}

.card__tasks--title-accent {
  font-size: 24px;
}

.card__tasks--title-text {
  font-size: 48px;
}
.card__plain-text {
  font-size: 16px;
}

.cards__container, .cards__container--earnings, .cards__container--collectibles {
  // width: calc(100vw - 40px);

  display: flex;
  flex-direction: column;
  // gap: 8px;
  // height: calc(100vh - 311px);

  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
.cards__container--earnings {
  height: calc(100vh - 450px);
  @media screen and (max-height: 670px) {
    height: calc(100vh - 420px);
  }
}

.cards__container--collectibles {
  height: calc(100vh - 180px);
  //@media screen and (max-height: 670px) {
  //  height: calc(100vh - 180px);
  //}
}

.card__task {
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 15px 15px;
}

.earning-card__task{
    display: flex;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 12px 13px -16px rgba(0, 0, 0, 0.2);
    padding: 16px;
    //align-items: center;
    gap: 8px;
    justify-content: space-between;
}

.earning-card-block{
    display: flex;
    gap: 8px;
}

.card__task--text {
  font-size: 16px;
}
.card__task--text-accent {
  font-size: 16px;
}

.card__column-to-row {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
}
.cards-container--top {
  // height: calc(100vh - 140px);
}
.payment-blok {
  height: 100%; 
}
.redeem-cards-container {
  //height: 100%;
  // width: calc(100vw - 40px);
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: space-between;
  // gap: 20px;
  margin: 0 auto;
    
  // position: absolute;
  // bottom: 110px;
  // left: 0;
  // right: 0;
}

.redeem-cards-container2 {
    height: 100%;
    width: calc(100vw - 40px);
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto;
    
  //position: absolute;
  //bottom: 110px;
  //left: 0;
  //right: 0;
}

.main-icon {
  width: 100px;
  height: 100px;
  path {
    fill: $violet
  }
}

.cards__redeem-button {
    display: flex;
    height: 56px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: $violet;
    
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.cards__redeem-button:hover{
    transition: 0.5s ease;
}

.wallet-adapter-button {
  width: calc(100vw - 120px) !important;
}

.wallet-adapter-button-trigger {
}


.redeem-text-btn{
    font-family: "SpaceGrotesk";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
    letter-spacing: -0.64px;
    align-items: center;
}

.counter-of-ppurs{
    text-align: center;
    font-family: "SpaceGrotesk";
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.purr-counter-text{
    font-family: "SpaceGrotesk";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin: 0 auto;
}

.wrapper-of-ppurs{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.gap-of-wrapper-of-ppurs{
    //gap: 120px;
    gap: 30px;
}

.form-for-crypto-wallet{
   // display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #FFF;
}

.label-for-crypto-wallet{
        text-align: right;
        font-family: "SpaceGrotesk";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 102%;
        letter-spacing: -0.48px;
}

.input-for-crypto-wallet{
    background: none;
    outline: none;
    border: none;
    width: 85%;
    
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.input-error-text {
}

.wrapper-for-crypto-wallet{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}

.crypto-icon, .set-wallet{
    position: absolute;
    right: 20px;
   // bottom: 15px;
    bottom: 93px;
}

.set-wallet {
  path {
   // fill: $accent
  }
}

.top-container {
 height: calc(100vh - 140px);
    
 padding: 10px 27px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 height: 100vh;
 width: 100%;
 position: relative;
 overflow: hidden;
}


.cards__bottom--main {
  width: 100vw;
  display: flex;
  align-items: end;
  justify-content: center;
  //max-width: 320px;
  gap: 10px;
  z-index: 0;
}
//.card__bottom--icon {
//  width: 26px;
//  height: 26px;
//}
.card__bottom--main {
  width: 155px;
  height: 64px;
  //padding: 20px;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 600;
  //line-height: 23.44px;
  font-family: sans-serif, Roboto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  //z-index: 1000;
  margin-bottom: 1px;
}


.card__task--text {
  display: flex;
  align-items: center;
  font-family: 'SpaceGrotesk', sans-serif;
  font-size: 16px;
  font-weight: 900;
  line-height: 19.09px;
}

.card__task--icon {
  min-width: 30px;
  text-align: center;
  margin-right: 5px;
  display: inline-block;
  vertical-align: text-top;
}

.small-card-task-text{
  font-family: 'SpaceGrotesk', sans-serif;
  font-size: 12px;
  font-weight: 900;
  line-height: 14.32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;  
  color: $violet;

  svg {
    vertical-align: text-top;
  }

  .disabled--bright & {
    color: #fff;
  }
}

.small-card-task-icon {
  margin-left: 5px;
  min-width: 36px;
  text-align: center;
}

.small-card-task-text--accent {
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.claim-text-card{
    font-family: "Roboto";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.claim-row{
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
  .details-section {
    .tasks-list {
      padding: 0;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
  }
}
.new-card__task{
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, .15);

    &.disabled--bright {
      border-bottom: 1px solid rgba(255, 255, 255, .45);
    }

    p {
      margin: 0;
    }
}

.card__column-to-row{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.disabled--bright {
  pointer-events: none;
  cursor: default;
  opacity: 0.40;
}
.card--img-paddle {
  &.card--img-paddle--grayscale {
    filter: grayscale(100%);
  }
}

/// Earnings page

.cards-container--referral {
  margin-bottom: 60px;

  @media screen and (max-height: 670px) {
    margin-bottom: 30px;
  }
}

.friends-card {
  display: flex;
  flex-direction: column;
}
.friends-card--title {
  font-size: 20px;
  font-family: Roboto, sans-serif;
  font-weight: 600;
}
.friends-card--description {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 600;
}

.referral-task-container {
  max-width: calc(100vw - 40px);
  min-height: 70px;
  height: 70px;
  padding: 16px 24px 16px 24px;
  gap: 8px;
  border-radius: 12px;
  border: 2px solid $violet;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.referral-task-container--claimable {
}

.referral-task-container--text {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 600;

  @media screen and (max-width: 400px) {
    max-width: 156px;
  }
  &.referral-task-container--text-claimable {
  }
}


.referral-task-container--invite {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  cursor: pointer;

  @media screen and (max-width: 400px) {
    max-width: 108px;
  }

  &.referral-task-container--invite-claimable {
    padding: 10px 15px 10px 15px;
    gap: 10px;
    border-radius: 4px;
  }
}


.referrals-number--container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
  @media screen and (max-height: 670px) {
    margin-top: 20px;
  }
}

.referrals-number--number {
  font-family: Roboto, sans-serif;
  font-size: 80px;
  font-weight: 700;
  text-align: center;
}

.referrals-number--dim {
  font-family: Roboto, sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 65.63px;
  text-align: center;
  opacity: 0.2;
}

.referrals-number--space {
  width: 46px;
  height: 1px;
  opacity: 0.2;
}

.token-block{
    margin: 0 auto;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
}

.cards__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(227px, 1fr));
  gap: 10px;

  @media screen and (max-width: 768px) {
    display: block;
    margin: 0 -20px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.card-inventory {
  padding: 20px;
  border-radius: 20px;
  align-items: center;
  flex-direction: row;
  position: relative;
  border: 1px solid #FFFFFF26;
  background: #131720;

  .hidden {display: none;}

  .card-inventory--img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 115px;
    border-radius: 12px;
    margin: 0 0 20px;
    text-align: center;
    background: #03070F;
  }

  .card-inventory--title {
    margin-bottom: 2px;
    color: white;
    font-family: 'SpaceGrotesk', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.42px;
  }

  &.card--selected {
    border: 1px solid #3A849A;
  }

  &.settings-card--active {
    cursor: pointer;
  }

  &.settings-card--disabled {
    pointer-events: none;
  }
}

.container-text-for-collectibles{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;

}

.row-text-for-collectibles{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.gap-for-icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.wrapper-for-card{
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
}

.id-of-selector{
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background: #3A849A;
    
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 1000px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;
    font-family: "Roboto";
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}