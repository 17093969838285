.game-wrapper {
  user-select: none;

  .react-p5 {
    text-align: center;
    @media screen and (max-width: 576px) and (max-height: 576px) {
      margin: -30px 0 -40px;

      .p5Canvas {
        width: 320px !important;
        height: 320px !important;
      }
    }
    @media screen and (max-width: 520px) and (max-height: 479px) {
      margin: -50px 0;
    }
  }

  @media screen and (max-width: 360px) {
    .telegramPrimaryText {
      margin: 0 0 .5rem;
    }
  }
}


@media screen and (max-width: 768px) {
  .buttons-holder {
    display: inline-block;
    position: relative;
  }
}

.buttons-frame {
  display: flex;
  flex-grow: 1;
  justify-content: center;

  > button {
    margin-left: 10px;
    background: linear-gradient(0deg, rgba(169, 58, 255, 0.4) 0%, rgba(169, 58, 255, 0) 100%);
    border: 1px solid #A93AFF;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      vertical-align: middle;
      fill: white;
    }
  }
}